var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "renewal-warning-modal",
        size: "md",
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
        title: "Future Filing Warning",
      },
    },
    [
      _c("br"),
      _c("p", [
        _vm._v("\n    This free Renewal Service will allow us to manage your "),
        _c("strong", [_vm._v("upcoming")]),
        _vm._v(" Renewals and Annual Report Filings.\n  "),
      ]),
      _c(
        "p",
        [
          _vm._v(
            "\n    If you would like us to perform a past-due Annual Report Filing, please refer to our\n    "
          ),
          _c(
            "b-link",
            {
              attrs: {
                variant: "primary",
                to: { name: "registrations" },
                target: "_blank",
              },
            },
            [_vm._v("\n      Registrations Page\n    ")]
          ),
          _vm._v("\n    and choose the correct filing option.\n  "),
        ],
        1
      ),
      _c(
        "template",
        { slot: "modal-footer" },
        [
          _c(
            "b-button",
            {
              staticClass: "submit-btn",
              attrs: { variant: "info", type: "submit" },
              on: {
                click: function ($event) {
                  return _vm.hide()
                },
              },
            },
            [_vm._v("\n      I understand\n    ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }