var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h3", [_vm._v("Free Compliance Service")]),
      _c("multi-select", {
        attrs: {
          value: _vm.selectedJurisdictions,
          "track-by": "id",
          multiple: true,
          label: "state_province_region",
          placeholder: "Select jurisdictions for compliance service",
          options: _vm.availableJurisdictions,
        },
        on: {
          select: function ($event) {
            return _vm.submitted($event.state_province_region)
          },
          remove: function ($event) {
            _vm.removeFromCart({
              id: _vm.cartItemForJurisdiction($event.state_province_region).id,
            })
          },
        },
        scopedSlots: _vm._u([
          {
            key: "singleLabel",
            fn: function ({ option }) {
              return [
                _vm._v("\n      " + _vm._s(option.jurisdiction) + "\n    "),
              ]
            },
          },
        ]),
      }),
      _c("renewal-warning-modal"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }