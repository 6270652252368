<template>
  <div>
    <h3>Free Compliance Service</h3>

    <multi-select
      :value="selectedJurisdictions"
      track-by="id"
      :multiple="true"
      label="state_province_region"
      placeholder="Select jurisdictions for compliance service"
      :options="availableJurisdictions"
      @select="submitted($event.state_province_region)"
      @remove="removeFromCart({ id: cartItemForJurisdiction($event.state_province_region).id })"
    >
      <template slot="singleLabel" slot-scope="{ option }">
        {{ option.jurisdiction }}
      </template>
    </multi-select>
    <renewal-warning-modal />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import MultiSelect from 'vue-multiselect'
  import RenewalWarningModal from './RenewalWarningModal'

  export default {
    name: 'ComplianceProductSpecialization',

    components: {
      MultiSelect,
      RenewalWarningModal,
    },

    props: {
      product: Object,
      company: Object,
    },

    data() {
      return {}
    },

    computed: {
      ...mapGetters('checkout', ['cartItems']),
      ...mapGetters('jurisdictions', ['jurisdictions', 'findByAbbreviation', 'findByName']),
      ...mapGetters('companies', ['activeComplianceServices']),

      availableJurisdictions() {
        if (this.activeComplianceServices.length) {
          return this.jurisdictions.filter(j => !this.activeComplianceServices.map(s => s.jurisdiction.state_province_region).includes(j.state_province_region))
        } else {
          return this.jurisdictions
        }
      },

      selectedJurisdictions() {
        return this
          .cartItems
          .filter(item => item.title === 'Compliance Service')
          .map(item => this.findByAbbreviation(item.data.state) || this.findByName(item.data.state))
      },
    },

    async mounted() {
      await this.$store.dispatch('jurisdictions/load')
      await this.loadActiveComplianceServices({ id: this.company.id })
    },

    methods: {
      ...mapActions('checkout', ['addToCart', 'removeFromCart']),
      ...mapActions('companies', ['loadActiveComplianceServices']),

      cartItemForJurisdiction(jurisdiction) {
        return this.cartItems.find(
          item => item.product_id === this.product.id && item.data.state === jurisdiction
        )
      },
      submitted(jurisdiction) {
        this.addToCart({ ...this.product, data: { state: jurisdiction } })
        // TODO be sure we don't need to reimplement this check for cart add success
        // if (addedToCart) {
        this.$emit('completed')
        // }
      },
    },
  }
</script>
