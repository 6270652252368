<template>
  <b-modal
    id="renewal-warning-modal"
    size="md"
    no-close-on-esc
    no-close-on-backdrop
    title="Future Filing Warning"
  >
    <br>
    <p>
      This free Renewal Service will allow us to manage your <strong>upcoming</strong> Renewals and Annual Report Filings.
    </p>
    <p>
      If you would like us to perform a past-due Annual Report Filing, please refer to our
      <b-link variant="primary" :to="{ name: 'registrations'}" target="_blank">
        Registrations Page
      </b-link>
      and choose the correct filing option.
    </p>

    <template slot="modal-footer">
      <b-button class="submit-btn" variant="info" type="submit" @click="hide()">
        I understand
      </b-button>
    </template>
  </b-modal>
</template>

<script>

export default {
  name: 'FutureFilingWarning',
  async mounted() {
    this.$bvModal.show('renewal-warning-modal')
  },
  methods: {
    hide() {
      this.$bvModal.hide('renewal-warning-modal')
    },
  },
}
</script>
